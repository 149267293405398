@import 'tailwindcss/base';
@import 'tailwindcss/components';
.css--btn {
	@apply rounded-full
        bg-orange-200
        px-4
        py-2
        inline-flex
        items-center
        text-xs
		transition-all
        md:px-5
        md:py-3;

	&.css--btn-arrow::after {
		content: '';
		@apply transform
            rotate-45
            border-t
            border-r
            w-2
            h-2
            border-black
			transition-all
            ml-1;
	}

	&.css--btn-cta {
		@apply bg-red-500
            text-white;
	}

	&:hover {
		@apply bg-opacity-80;

		&::after {
			@apply ml-2;
		}
	}
}

.css--link {
	@apply text-green-500
        border-b
        border-green-200;

	&:hover {
		@apply border-green-500;
	}
}

.css--menu-toggle span {
	&::before,
	&::after {
		content: '';
		@apply absolute
            left-0
            w-full
            border-t-2
            border-blue-800
            origin-left
            transition;
	}
	&::before {
		@apply top-0;
	}
	&::after {
		@apply bottom-0;
	}
	& i {
		@apply absolute
            left-0
            w-full
            top-1/2
            -mt-px
            border-t-2
            border-blue-800
            transform
            transition;
	}
}
.css--menu-toggle:hover {
	@apply opacity-80;
}

.css--show-menu {
	& .css--menu-toggle span {
		&::before {
			transform: rotate(40deg);
		}
		&::after {
			transform: rotate(-40deg);
		}
		& i {
			@apply -translate-x-full;
		}
	}

	& .css--nav {
		@apply translate-y-0;

		& li {
			@apply opacity-100;
		}
	}
}

/*
.css--front {
	background-image: url('/dist/img/bg_mob.png');

	@screen md {
		background-image: url('/dist/img/bg_des.png');
	}
}
*/
#particles-js canvas {
	@apply absolute
		top-0
		left-0
		w-full
		h-full;
}
@import 'tailwindcss/utilities';
